import http from './http'
import processCsv from 'utils/process-csv'

export function expenses (params) {
  return http.get(`/expenses`, { params })
}

export function expensesCSV (params) {
  http.get(`/expenses/csv`, { params }).then(d => processCsv(d, 'Mobilleo expense report'))
}
